@import "src/colors";
.group_button {
  margin-bottom: 1rem;
  .save_button {
    margin: 0 1rem;
    // background: $light_orange;
    background: $blue_variant_three;
    color: $white;
    border: none;
    text-transform: initial;
    font-weight: 800;
    &:hover {
      // background: $light_orange_three_opacity !important;
      background: $blue_variant_three_opacity !important;
    }
  }
  .close_button {
    background: $dark_gray;
    color: $white;
    border: none;
    text-transform: initial;
    font-weight: 800;
    &:hover {
      background: $met__gray;
    }
  }
}
.group_add_update_cancel{
  margin-bottom: 1rem;
  .MuiButton-containedPrimary {
    margin: 0.5rem;
    background-color: $blue_variant_three !important;
    &:hover {
      background-color: $blue_variant_three_opacity !important;
    }
  }
}
