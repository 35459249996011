@import "src/colors";


.create_draft {
  margin: 2rem;
  margin-bottom: 8rem;

  .sent_program_draft {
    margin-top: 1.2rem;
  }

  .recipients {
    margin-top: 8px;
  }

  .lable {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .online_program_group {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;

    .online_program {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;

      // .program_checkbox_container {
      //   display: flex;
      //   flex-wrap: wrap;
      //   gap: 10px;
      .freeProgram {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-wrap: wrap;
      }
      // }

      .MuiTypography-body1 {
        font-weight: bold !important;
        font-size: 1.3rem;
        font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
      }
    }

    .online_program_info {
      flex: 0.5;
      display: flex;
      justify-content: flex-end;

      .info_icon {
        cursor: pointer;
        color: $blue_variant_three;
        font-size: 3rem;

        &:hover {
          color: $blue_variant_three_opacity;
        }
      }
    }

    .visitPdfButtonContainer {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
        color: white;
        background: dodgerblue;
        padding: 8px 12px;
        border: 4px solid lightgray;
        border-radius: 13px;
      }
    }
  }
}

.MuiTypography-body1 {
  font-size: 1.3rem;
  font-weight: bold;
}

.WAMuiChipInput-inputRoot-7.WAMuiChipInput-outlined-10 {
  padding: 8px 8px 0 8px !important;
}

.OOPDescription {
  text-align: justify;
  text-justify: inter-word;
  padding: 0px 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

.MuiTypography-h6 {
  font-size: 2rem !important;
}

.update_button {
  justify-content: center;
  display: flex;
  margin: 4.5rem 0;
  margin-bottom: 150px;

  .send_pdf {
    background-color: #8e8e8e;
    color: $white;
    font-weight: 800;

    &:hover {
      background-color: #ff9a00ed;
    }
  }

  .Pdf_Preview {
    margin-left: 1rem;
    background-color: #8e8e8e;
    color: $white;
    font-weight: 800;

    &:hover {
      background-color: #ff9a00ed;
    }
  }
}

@media (max-width: 520px) {
  .online_program {
    .MuiTypography-body1 {
      font-size: 1rem !important;
    }
  }
  .create_draft {
    .lable {
      font-size: 1rem;
    }
  }
  .visitPdfButtonContainer {
    flex-direction: column;
    gap: 15px;
    a {
      padding: 16px !important;
    }
  }
}
