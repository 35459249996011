* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"),
    url(../src/fonts/ArialMtBold.woff) format("woff");
  // src: url("http://db.onlinewebfonts.com/t/ef6bdf5ef216552c7e9869841e891ca0.woff");
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"),
    url(../src/fonts/HeveticaNeue.woff) format("woff");
  // src: url("http://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.woff");
}

@font-face {
  font-family: "Helvetica bold1";
  src: local("Helvetica bold1"),
    url(../src/fonts/HelveticaBold1.woff) format("woff");
  // src: url("http://db.onlinewebfonts.com/t/50296ce96471759b7ec49aec9ad37f3c.woff");
}
@font-face {
  font-family: "Helvetica bold";
  src: local("Helvetica bold"),
    url(../src/fonts/HelveticaBold.woff) format("woff");
  // src: url("http://db.onlinewebfonts.com/t/b812035768ba3b9e92b0a01c747eecbd.woff");
}
@font-face {
  font-family: "Helvetica italic";
  src: local("Helvetica italic"),
    url(../src/fonts/HelveticaItalic.eot) format("woff");
}
