@import "src/colors";

// .Pro_badge {
//   height: 40px !important;
//   padding: 5px !important;
//   border-radius: 50px !important;
//   background-color: white !important;
//   position: fixed;
//   bottom: 10px;
// }

.header {
  padding: 28px;
  background: $blue_variant_three_with_gradiant;
  // background: linear-gradient(#00486c, #00a0de);
  top: 0;
  position: -webkit-sticky !important;
  position: sticky !important;

  z-index: 100;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  height: 13vh;
  align-items: center;
  .header_top {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    //margin-top: 1rem;
    .header_left {
      display: flex;
      align-items: center;
      flex-direction: row;
      .header__input {
        display: flex;
        flex-direction: row;
        //display: none;
        padding: 6px;
        background-color: $white;
        border-radius: 20px;
        margin-top: 12px;
        input {
          width: 100%;
          border: none !important;
          margin-left: 10px;
          font-size: 15px;
          background: none;
          &:focus {
            outline: none;
          }
        }
      }
      .title_text {
        color: $white;
        margin-left: 1rem;
        font-size: 30px;
        font-weight: bolder;
        font-family: Helvetica;
        text-transform: capitalize;
      }
      .title_text_small {
        color: $white;
        margin-left: 1rem;
        font-size: 25px;
        font-weight: bolder;
        font-family: Helvetica;
        text-transform: capitalize;
      }
      .title_text_large {
        color: $white;
        margin-left: 1rem;
        font-size: 45px;
        font-weight: bolder;
        font-family: Helvetica Neue;
        text-transform: capitalize;
      }

      .body__right {
        // background-color: #499FE3 !important;
        background-color: #499fe3 !important;
        border-radius: 50% !important;
        box-shadow: 0 20px 45px #00486c;
        display: flex;
        align-items: center;
        .img_icon_list {
          align-items: center;
          //margin-top: 2px;
          //margin-left: 1px;
          height: 32px !important;
          width: 35px !important;
          margin-right: 0 !important;
          object-fit: contain;
          cursor: pointer;
          padding: 3px;
          color: white;
          text-align: center !important;
        }
      }
      .body__right__without_shadow {
        // background-color: #499FE3 !important;
        background-color: #499fe3 !important;
        border-radius: 50% !important;
        //box-shadow: 0 20px 45px #00486c;
        display: flex;
        align-items: center;
        .img_icon_list {
          align-items: center;
          //margin-top: 2px;
          //margin-left: 1px;
          height: 32px !important;
          width: 35px !important;
          margin-right: 0 !important;
          object-fit: contain;
          cursor: pointer;
          padding: 3px;
          color: white;
          text-align: center !important;
        }
      }
      .back_arrow {
        color: $white;
        font-weight: bolder;
        cursor: pointer;
      }
    }
    .button_section {
      display: flex;
      flex-direction: column;

      .header_buttons {
        text-align: right;
        margin-top: -12px;
        .MuiButton-contained {
          // height: 2.5rem;
          // width: 1rem;
          // margin: 0 5px;
          // text-transform: inherit;
          // //background-color: $met_orange;
          // background-color: #8e8e8e;
          // color: $white;
          // font-weight: 800;
          // padding: 0;
          // border: none;
          background-color: #8e8e8e;
          margin-right: 6px !important;
          padding: 7px 0;
          font-size: 18px;
          font-weight: 900;
          min-width: 52px;
          border: none;
          box-shadow: none;
          color: $dark_gray;
          &:hover {
            background-color: #ff9a00ed;
          }
        }
        .img_icon_header {
          height: 25px !important;
          width: 25px !important;
          margin-right: 0 !important;
          margin-bottom: 2px;
        }
      }
      .versionFilter__buttons {
        display: flex;
        justify-content: center;
        margin-top: 2px;
        .version__button {
          margin-right: 6px;
          padding: 5px 0;
          font-size: 18px;
          font-weight: 1000;
          min-width: 52px;
          border: none;
          box-shadow: none;
          // color: $dark_gray;
          color: $dark_gray;
          //background-color: white;
          // background-image: linear-gradient(	#B0B0B0,#F0F0F0,#B0B0B0);
          background-image: linear-gradient(#b0b0b0, whitesmoke, #b0b0b0);
        }
        .version__button__active {
          background-color: whitesmoke;
          color: rgb(69, 152, 212);
        }
      }
    }

    .button_section_right {
      display: flex;
      flex-direction: column;
      text-align: right;
      .header_buttons_set_right {
        text-align: right !important;
        align-items: unset !important;
        .MuiButton-contained {
          background-color: #8e8e8e;
          margin-right: 6px !important;
          padding: 7px 0;
          font-size: 18px;
          font-weight: 900;
          min-width: 52px;
          border: none;
          box-shadow: none;
          color: $dark_gray;
        }
      }
    }
  }
  .header__bottom {
    .header__input {
      display: flex;
      flex-direction: row;
      //display: none;
      padding: 6px;
      background-color: $white;
      border-radius: 20px;
      margin-top: 12px;
      input {
        width: 100%;
        border: none !important;
        margin-left: 10px;
        font-size: 15px;
        background: none;
        &:focus {
          outline: none;
        }
      }
    }
  }

  // .versionFilter__buttons {
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 2px;
  //   .version__button {
  //     margin-right: 6px;
  //     padding: 5px 0;
  //     font-size: 18px;
  //     font-weight: 1000;
  //     min-width: 52px;
  //     border: none;
  //     box-shadow: none;
  //     // color: $dark_gray;
  //     color: $dark_gray;
  //     background-color: white;
  //   }
  //   .version__button__active {
  //     background-color: whitesmoke;
  //     color: rgb(69,152,212);
  //   }
  // }
}

//img {
//  height: 60px;
//  width: 60px;
//  object-fit: contain;
//  margin-right: 10px;
//}

.img_icon {
  height: 40px !important;
  width: 40px !important;
  margin-right: 0 !important;
  padding-bottom: 0.5rem !important;
}

// @media (max-width: 990px) {
//   .header {
//     padding: 35px 0;
//     .header_top {
//       flex-direction: column;
//       .header_buttons {
//         margin-top: 9px !important;
//         justify-content: center;
//         display: flex;
//         // flex-direction: column;
//         .MuiButton-contained {
//           margin: 4px 4px;
//         }
//       }
//     }
//     .header__input {
//       margin: 20px 10px 0 10px;
//     }
//   }
// }
.programWithArchive {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.action_button {
  padding: 0;
  background: linear-gradient(
    rgb(176, 176, 176),
    whitesmoke,
    rgb(176, 176, 176)
  );
  .MuiButton-label {
    color: #4d4d4d;
    font-size: 9px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 12px 5px;
    height: auto;
    position: -webkit-sticky !important;
    position: sticky !important;
    .header_top {
      flex-direction: row;
      .header_left {
        display: flex;
        align-items: center;
        flex-direction: column;
        // margin-top: 1rem;
        .title_text {
          color: $white;
          margin-left: 0.5rem !important;
          text-align: center;
          font-size: 20px !important;
          font-weight: bolder;
          margin-top: 0.5rem;
          font-family: Helvetica;
          margin-left: 1rem;
        }
        .title_text_large {
          color: $white;
          margin-left: 1rem;
          font-size: 25px;
          font-weight: bolder;
          font-family: Helvetica Neue;
        }
      }
      .header_left_for_draft {
        display: flex;
        align-items: center;
        flex-direction: row;
        .header__input {
          display: flex;
          flex-direction: row;
          //display: none;
          padding: 6px;
          background-color: $white;
          border-radius: 20px;
          margin-top: 12px;
          input {
            width: 100%;
            border: none !important;
            margin-left: 10px;
            font-size: 15px;
            background: none;
            &:focus {
              outline: none;
            }
          }
        }
        .title_text {
          color: $white;
          margin-left: 0.5rem;
          font-size: 15px !important;
          font-weight: bolder;
          margin-top: 0.5rem;
          font-family: Helvetica;
        }
        .title_text_large {
          color: $white;
          margin-left: 1rem;
          font-size: 25px;
          font-weight: bolder;
          font-family: Helvetica Neue;
        }
        .body__right {
          // background-color: #499FE3 !important;
          background-color: #499fe3 !important;
          border-radius: 50% !important;
          box-shadow: 0 20px 45px #00486c;
          display: flex;
          align-items: center;
          .img_icon_list {
            align-items: center;
            //margin-top: 2px;
            //margin-left: 1px;
            height: 37px !important;
            width: 40px !important;
            margin-right: 0 !important;
            object-fit: contain;
            cursor: pointer;
            padding: 3px;
            color: white;
            text-align: center !important;
          }
        }
        .body__right__without_shadow {
          // background-color: #499FE3 !important;
          background-color: #499fe3 !important;
          border-radius: 50% !important;
          //box-shadow: 0 20px 45px #00486c;
          display: flex;
          align-items: center;
          .img_icon_list {
            align-items: center;
            //margin-top: 2px;
            //margin-left: 1px;
            height: 37px !important;
            width: 40px !important;
            margin-right: 0 !important;
            object-fit: contain;
            cursor: pointer;
            padding: 3px;
            color: white;
            text-align: center !important;
          }
        }
        .back_arrow {
          color: $white;
          font-weight: bolder;
          cursor: pointer;
        }
      }
      .button_section {
        display: flex;
        flex-direction: column;
        // margin: auto;
        .header_buttons {
          text-align: right !important;
          //justify-content: center;
          // flex-direction: column;
          .MuiButton-contained {
            margin-right: 6px;
            padding: 5px 0;
            font-size: 15px !important;
            font-weight: 900 !important;
            min-width: 40px !important;
            border: none;
            box-shadow: none;
            color: $dark_gray;
          }
        }
        .versionFilter__buttons {
          display: flex;
          margin-left: auto;
          .version__button {
            margin-right: 6px;
            padding: 5px 0;
            font-size: 15px !important;
            font-weight: 900 !important;
            min-width: 40px !important;
            border: none;
            box-shadow: none;
            color: $dark_gray;
          }
          .version__button__active {
            background-color: whitesmoke;
            color: rgb(69, 152, 212);
          }
        }
        .header__input {
          text-align: right !important;
          display: flex;
          flex-direction: row;
          //display: none;
          padding: 6px;
          background-color: $white;
          border-radius: 20px;
          margin-top: 12px;
          width: 80%;
          margin-left: auto !important;
          input {
            width: 100%;
            border: none !important;
            //margin-left: 10px;
            font-size: 15px;
            background: none;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .header_top_right {
        flex-direction: row;
        justify-content: unset;
        .header_left {
          display: flex;
          align-items: center;
          flex-direction: column;
          .title_text {
            color: $white;
            margin-left: 0rem;
            font-size: 15px !important;
            font-weight: bolder;
            margin-top: 0.5rem;
          }
        }

        .button_section_right {
          display: flex;
          flex-direction: column;
          text-align: right;
          .header_buttons_set_right {
            text-align: right !important;
            align-items: unset !important;
          }
        }
      }

      // .header_buttons {
      //   margin-top: 9px !important;
      //   justify-content: center;
      //   display: flex;
      //   // flex-direction: column;
      //   .MuiButton-contained {
      //     margin: 4px 4px;
      //   }
      // }
    }
    .header__input {
      // margin: 20px 10px 0 10px;
      margin: auto;
      padding: 5px !important;
    }
  }
}

@media (max-height: 500px) {
  .header {
    position: -webkit-sticky !important;
    position: sticky !important;
    display: block;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .header {
    height: auto;
  }
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .header {
    height: auto;
  }
}

@media (min-width: 1440px) {
  .header {
    height: auto;
  }
}
@media (min-width: 1680px) {
  .header {
    height: auto;
  }
}
