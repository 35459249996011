@import "src/colors";

.marginBottom {
  margin-bottom: 10px;
}

.formMarginTop {
  //margin-top: 4px;

  .MuiOutlinedInput-input {
    padding: 10px;
  }

  .MuiInputLabel-outlined {
    transform: translate(12px, 13px) scale(1);
  }


  .address_fields {
    display: flex;
    justify-content: space-between;

    .street_input {
      margin-right: 10px;
    }
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-top: 5px;
  }
}

.header_buttons {
  .MuiButton-contained {
    margin-top: 10px;
    margin-bottom: 8px;
    text-transform: inherit;
    background-color: $blue_variant_three;
    color: white;
    font-weight: 800;
    border: none;

    &:hover {
      background-color: $blue_variant_three_opacity;
    }
  }
}

@media(max-width: 990px) {
  .formMarginTop {
    .address_fields {
      display: flex;
      flex-direction: column;

      .street_input {
        margin-right: 0 !important;
        width: 100%;
      }
    }
  }
}
