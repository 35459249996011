.Body-class {
  // background-color: #F7F9FC;
  background-color: #f7f9fc;
  background-image: url("../src/fonts/mainbackground.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  content: "Optimum";
  // opacity: 0.5;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: dodgerblue !important;
}

.main-paper-other {
  overflow: auto;
  background-image: url("../src/fonts/background.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.main-paperBuser-other {
  overflow: auto;
  // background-image: url("../src/fonts/background.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.main-paper-safari {
  height: auto !important;
  overflow: unset !important;
  background-image: url("../src/fonts/background.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  height: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.main-paperBuser-safari {
  height: auto !important;
  overflow: unset !important;
  // background-image: url("../src/fonts/background.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  height: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 767px) {
  .main-paper-other {
    overflow: auto;
    background-image: url("../src/fonts/background.png");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    height: 93vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    zoom: 1;
  }

  .main-paper-safari {
    // overflow: auto;
    height: auto !important;
    overflow: unset !important;
    background-image: url("../src/fonts/background.png");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    height: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    zoom: 1;
  }

  .MuiPaper-elevation3 {
    box-shadow: none !important;
  }
}

@media (max-height: 400px) {
  .main-paper-other {
    overflow: auto;
    background-image: url("../src/fonts/background.png");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    height: 90vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-paper-safari {
    height: auto !important;
    overflow: unset !important;
    background-image: url("../src/fonts/background.png");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    height: 90vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiPaper-elevation3 {
    box-shadow: none !important;
  }
}

// body:after{
//   // margin-left: 0.5rem;
//   content: "OPTIMUM" !important;
//   position: absolute !important;
//   font-size: 34vh;
//   font-weight: 600;
//   letter-spacing: 24px;
//   text-align: center;
//   justify-content: center;
//   margin-left: 16px;
//   // transform: rotate(00deg);
//   // -webkit-transform: rotate(300deg);
//   color: #D2EEFB;
//   top: 30%;
//   left: 0;
//   z-index: -1;
// }

// .main-paper:after{
//   // margin-left: 0.5rem;
//   content: "O" !important;
//   position: absolute !important;
//   font-size: 245px;
//   font-weight: 600;
//   letter-spacing: 15px;
//   text-align: center;
//   justify-content: center;

//   // transform: rotate(00deg);
//   // -webkit-transform: rotate(300deg);
//   margin-left: 40%;
//   color: green;
//   top: 30%;
//   left: 0;
//   // z-index: -1;
// }
