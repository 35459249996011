$light_purple: #4a5cbf;
$light_blue: #614abf2e;
$light_orange:#eb6825;
$light_orange_three_opacity: #eb6725ed;
$white:#ffffff;
$light_green:#60b529f7;
$light_black:#000000ad;
$red:#ff0000;
$red_opacity:#ff0000c5;
$mid_purple:#3E41A7;
$gray:#EAEAEA;
$black:#000000;
$light_gray:#C6C6C6;
$blue_variant_two:#648ab1;
$mid_gray:#808080b8;
$dark_gray:#4d4d4d;
$shadow__gray:#404040;
$mid__green:#2d6d04f7;
$met__gray:#767676;
$light_white:#e7e7e7;
$sky_blue:#4ca5bff2;
$blue_variant_three:#006CA0;
$blue_variant_three_with_gradiant: linear-gradient(#00486c, #00a0de);
$blue_variant_three_opacity:#006ca0ed;
$sky__dark__blue:#2e9bbbf2;
$met_orange:#ff9a00;
$light_met_orange:#ff9a00ed;
$light_pink:#F35F74;
