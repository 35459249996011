.backgroung_set{
    background: linear-gradient(45deg, rgb(106, 120, 209), rgb(0, 164, 189)) !important;
    text-align: center;
    color: white;
    font-size: 30px !important;
}
.ok_button{
    text-align: center !important;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.success_icon{
    font-size: 50px !important;
    margin-top: 1rem;
}