@import "src/colors";

.inActive {
  .name, .icon {
    color: gray;
  }
}

.active {
  .name, .icon {
    color: $blue_variant_three;
  }
}
.img_icon_first{
  // height: 35px !important;
  // width: 30px !important;
  // margin-right: 0 !important;

  height: 22px !important;
  width: 18px !important;
  margin-right: 0 !important;
  padding-bottom: 1rem;
}
.img_icon{
    // height: 35px !important;
    // width: 30px !important;
    // margin-right: 0 !important;

    height: 25px !important;
    width: 20px !important;
    margin-right: 0 !important;
    padding-bottom: 1rem;
}

.ul_class {
  height: auto;
  .search_box {
    z-index: 2;
    position: fixed;
    top: 89%;
    width: 30%;
    left: 50%;
    transform: translate(-50%, -95%);
    background-color: white;
    display: flex;
    margin: 0;
    padding: 8px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .3);

    input {
      width: 100%;
      border: none !important;
      border-radius: 15px;
      margin-left: 10px;
      font-size: 20px;
      &:focus {
        outline: none;
      }
    }
  }
  

  ul {
    z-index: 2;
    position: fixed;
    top: 98%;
    left: 50%;
    transform: translate(-50%, -95%);
    background-color: white;
    display: flex;
    margin: 0;
    padding: 15px 0;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
  }

  ul li {
    // width: 25%;
    cursor: pointer;
    list-style: none;
    text-align: center;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, .2);
  }

  /* two items */
  .ul li:first-child:nth-last-child(2),
  .ul li:first-child:nth-last-child(2) ~ li {
    width: 50%;
  }

  /* three items */
  li:first-child:nth-last-child(3),
  li:first-child:nth-last-child(3) ~ li {
    width: 33.3333%;
  }

  /* four items */
  li:first-child:nth-last-child(4),
  li:first-child:nth-last-child(4) ~ li {
    width: 25%;
  }

  ul li:last-child {
    border-right: none;
  }

  ul li a {
    text-decoration: none;
    padding: 0 30px;
    display: block;
    font-size: 100%;
    transition: transform .2s;
    height: 1rem;
    padding-bottom: 0.5rem;
  }

  ul li a:hover {
    transform: scale(1.2);
  }

  ul li .button_dropdown {
    text-decoration: none;
    padding: 0 30px;
    display: block;
    font-size: 100%;
    transition: transform .2s;
  }
  ul li .button_dropdown:hover{
    transform: scale(1.2);
  }
}


@media(max-width: 425px) {
  .ul_class {
    .search_box {
      z-index: 2;
      position: fixed;
      top: 89%;
      width: auto;
      left: 50%;
      transform: translate(-50%, -95%);
      background-color: white;
      display: flex;
      margin: 0;
      padding: 8px;
      border-radius: 20px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
  
      input {
        width: 100%;
        border: none !important;
        border-radius: 15px;
        margin-left: 10px;
        font-size: 20px;
        &:focus {
          outline: none;
        }
      }
    }
    
    ul {
      //width: 80% !important;
      padding: 10px 0 !important;
    }

    ul li a {
      padding: 0 15px;
      height: 1rem;
      padding-bottom: 0.5rem;
    }
    ul li .button_dropdown{
      padding: 0 10px;
    }
    .inActive .name {
      font-size: 14px;
    }

    .active .name {
      font-size: 14px;
    }
  }
}
@media(min-width: 1920px) {
  .ul_class {
    .search_box {
      z-index: 2;
      position: fixed;
      top: 89%;
      width: 20%;
      left: 50%;
      transform: translate(-50%, -95%);
      background-color: white;
      display: flex;
      margin: 0;
      padding: 8px;
      border-radius: 20px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
  
      input {
        width: 100%;
        border: none !important;
        border-radius: 15px;
        margin-left: 10px;
        font-size: 20px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
